import $ from 'jquery'

const $doc = $(document)

function showSearchBar(event) {
  let el = $(this);
  event.preventDefault()

  el.toggleClass('active');
  if (el.hasClass('active')) {
    el.attr('aria-expanded', 'true')
  } else {
    el.attr('aria-expanded', 'false')
  }

  if ($(window).width() < 960) {
    $('#search-panel-mobile').slideToggle(300)
    if (el.hasClass('active')) {
      $('#search-panel-mobile .js-search-input').focus();
    } else {
      el.focus()
    }
  }
  else {
    $('.js-header').toggleClass('search-is-active')
    $('#search-panel-desktop').slideToggle(300)
    if (el.hasClass('active')) {
      $('#search-panel-desktop .js-search-input').focus();
    } else {
      el.focus()
    }
  }
}

export default function initSearch(){
  $('.js-search-button').on('click', showSearchBar);
  if ($(window).width() >= 960) {
    $doc.on('keydown', function (event) {
      if ($('.js-header').hasClass('search-is-active') && (event.key == "Escape")) {
        $('.js-search-button').attr('aria-expanded', 'false')
        $('.js-header').toggleClass('search-is-active')
        $('#search-panel-desktop').slideToggle(300)
        $('.js-search-button').focus()
      }
    })
    $doc.on('focusin', function (event) {
      var $target = $(event.target);
      if (!$target.closest('.js-header').length) {
        if ($('.js-header').hasClass('search-is-active')) {
          $('.js-search-button').attr('aria-expanded', 'false')
          $('.js-header').toggleClass('search-is-active')
          $('#search-panel-desktop').slideToggle(300)
        }
      }
    })
  }
}
