import $ from 'jquery'

function loadMore() {
  let canBeLoaded = true; 
  let bottomOffset = 2000; 
  let search = 'no';

  if ($('body').hasClass('search-results')) {
    search = 'yes';
  }

  $(window).scroll(function () {
    var data = {
      'action': 'loadmore',
      'query': php_vars.posts,
      'page': php_vars.current_page,
      'search': search
    };
    if ($(document).scrollTop() > ($(document).height() - bottomOffset) && canBeLoaded == true && ($('body').hasClass('search-results') || $('body').hasClass('archive') ) ) {
      //console.log('ajax');
      $.ajax({
        url: php_vars.ajaxurl,
        data: data,
        type: 'POST',
        beforeSend: function (xhr) {
          canBeLoaded = false;
        },
        success: function (data) {
          if (data) {
            $('#posts-list').find('.grid__column:last-of-type').after(data); 
            canBeLoaded = true; 
            php_vars.current_page++;
          }
        }
      });
    }
  });
}

export default function initLoadMore(){
  loadMore();
}
